<template>
    <slot v-if="disabled"></slot>
    <OColContainer v-else>
        <slot></slot>
    </OColContainer>
</template>

<script setup lang="ts">
import OColContainer from 'o365.vue.components.ColContainer.vue';

interface IBodyWrapperProps {
    disabled: boolean
};

const props = defineProps<IBodyWrapperProps>();
</script>